<template>
  <div id="userOrder" v-loading.fullscreen.lock="fullscreenLoading">
    <Dialog ref="dialog" :config="config" />
    <div>
      <div class="searchBox">
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          :range-separator="$fanyi('至')"
          :start-placeholder="$fanyi('开始日期')"
          :end-placeholder="$fanyi('结束日期')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="setFromDate"
        >
        </el-date-picker>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
      </div>
      <div class="dataBody">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{
            'text-align': 'center',
            background: '#F0F0F0',
            color: 'black',
            'font-size': '12px',
            'font-weight': '500',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column type="index" width="50" label="Nº">
          </el-table-column>
          <el-table-column
            prop="created_at"
            :label="$fanyi('时间')"
            width="100"
          >
          </el-table-column>
          <el-table-column :label="$fanyi('订单号')" width="176">
            <template slot-scope="scope">
              <button
                class="order_sn"
                @click="
                  $fun.routerToPage(
                    '/OrderDetails?order_sn=' +
                      scope.row.order_sn +
                      '&type=purchased',
                    true
                  )
                "
              >
                {{ scope.row.order_sn }}
              </button>
            </template>
          </el-table-column>
          <el-table-column
            prop="porder_sn"
            :label="$fanyi('跟踪号码')"
            width="186"
          >
            <template slot-scope="scope">
              <button
                class="order_sn"
                @click="
                  $fun.routerToPage(
                    '/deliveryDetails?type=purchase&order_sn=' +
                      scope.row.porder_sn
                  )
                "
              >
                {{ scope.row.porder_sn }}
              </button>
            </template>
          </el-table-column>

          <el-table-column prop="bill_method_name" :label="$fanyi('支付方式')">
            <template slot-scope="scope">
              <div>
                {{ $fanyi(scope.row.bill_method_name) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="invoice_id"
            :label="$fanyi('发票号')"
            width="144"
          >
          </el-table-column>
          <el-table-column prop="totalAmout" :label="$fanyi('总金额')">
            <template slot-scope="scope">
              <div>{{ $fun.EURNumSegmentation(scope.row.totalAmout) }} €</div>
            </template>
          </el-table-column>
          <el-table-column prop="count" :label="$fanyi('下载')">
            <template v-slot="scope">
              <img
                src="../../../../assets/user-img/download.svg"
                alt=""
                class="downloadIcon"
                @click="downLoad(scope.row)"
              />
              <a :href="scope.row.invoice_file" v-if="scope.row.invoice_file">
                <img
                  src="../../../../assets/user-img/down_red.png"
                  alt=""
                  class="downloadIcon"
              /></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.currentPage"
          :page-size="10"
          :page-sizes="[10, 20, 30, 50]"
          layout=" prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from "../../../../utlis/date";
import Dialog from "../../../../components/public/Dialog";
import axios from "axios";
import Europess from "../../../../api/wangZhi";
let Europe = Europess + "/client/";
export default {
  data() {
    return {
      value1: null,
      form: {
        page: 1,
        pageSize: 10,
        dateStart: "",
        dateEnd: "",
      },
      fullscreenLoading: false,
      tableData: [],
      total: 0,
      tableOption: [], //表格显示哪些选项
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      all: 0,
    };
  },
  components: { Dialog },
  computed: {},
  created() {
    this.form.page = 1;
    this.form.pageSize = 10;
    // 默认显示当月
    // this.form.dateStart = getCurrentMonthFirst() + " 00:00:00";
    // this.form.dateEnd = getCurrentMonthLast() + " 23:59:59";
    // this.value1 = [
    //   getCurrentMonthFirst() + " 00:00:00",
    //   getCurrentMonthLast() + " 23:59:59",
    // ];
    if (this.$route.query.status) {
      this.form.status = this.$route.query.status;
      this.$router.push({
        query: {},
      });
      this.getData(this.form);
    } else {
      this.getData(this.form);
    }
  },
  methods: {
    // 下载形式发票
    getFapiao(sn) {
      this.fullscreenLoading = true;
      let params = {
        order_sn: sn,
      };
    },
    // 下载发票
    downLoad(item) {
      if (item.invoice_file) {
        window.open(item.invoice_file);
      } else {
        let id = item.invoice_id;
        this.fullscreenLoading = true;
        // location.href = Europe + "user.invoice.downloadInvoice?invoice_id=" + id;
        axios
          .get(Europe + "user.invoice.downloadInvoice?invoice_id=" + id, {
            responseType: "blob", // 1.首先设置responseType对象格式为 blob:
          })
          .then((res) => {
            this.fullscreenLoading = false;
            if (res.size < 200) {
              return this.$message.warning(this.$fanyi("操作失败"));
            }
            let blob = new Blob([res], {
              type: "'application/pdf'",
            });
            // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
            let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
            // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
            let a = document.createElement("a");
            a.href = url;
            a.download = id + ".pdf";
            a.click();
            // 5.释放这个临时的对象url
            window.URL.revokeObjectURL(url);
            this.diaShow = !this.diaShow;
          })
          .catch((error) => {
            this.$message(this.$fanyi(error.message));
          });
      }
    },
    setFromDate() {
      if (this.value1) {
        this.form.dateStart = this.value1[0];
        this.form.dateEnd = this.value1[1];
      } else {
        this.form.dateStart = "";
        this.form.dateEnd = "";
      }
    },

    // 获取数据
    getData(datas) {
      this.tableData = [];
      this.$api
        .invoiceList(datas)
        .then((res) => {
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.$forceUpdate();
        })
        .catch((err) => {});
    },

    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
#userOrder {
  @import "@/css/mixin";
  width: 100%;
  button {
    cursor: pointer;
    &.order_sn {
      color: #1e2997;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .rowAndCenter {
    display: flex;
    align-items: center;
  }
  > nav {
    height: 50px;
    display: flex;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      height: 50px;
      padding: 10px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;
      text-align: center;

      color: #000000;
      line-height: 16px;

      cursor: pointer;
      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
      .label {
        display: inline-block;
        margin-right: 7px;
      }
      .numberCount {
        width: 18px;
        height: 18px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 18px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;
    .searchBox {
      display: flex;
      margin-bottom: 20px;
      .myInput {
        width: 400px;
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;
        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }
        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 500px;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          &:focus {
            border-color: #1e2997;
          }
        }
      }
      /deep/.el-date-editor {
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;
        .el-range-input {
          font-size: 14px;
        }
        .el-range-separator {
          width: 50px;
        }
      }
      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }
    .dataBody {
      margin-bottom: 40px;
      > ul {
        display: flex;
        // width: 1100px;
        > li {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          // line-height: 56px;
          font-size: 12px;

          color: #000000;
          // 设置一列
          &.oNO {
            flex: 0.33;
          }
          &.orderNumber {
            flex: 0 0 180px;
          }
          &.datePresentation {
            flex: 0 0 210px;
            text-align: center;
          }
        }
      }
      .downloadIcon {
        width: 22px;
        height: 21px;
        margin: 0 5px;
        cursor: pointer;
      }
      ul.ohead {
        height: 56px;
        background: #f0f0f0;
        li {
          overflow: hidden;
        }
        .tableHeadCon {
          cursor: default;
          width: 100%;
          text-align: center;
          line-height: 14px;
        }
      }
      ul.obody {
        height: 60px;
        margin: 0 -1px -1px 0;
        border: 1px solid #ededed;
        li {
          border-right: solid 1px #ededed;
          border-bottom: solid 1px #ededed;
          margin: 0 -1px -1px 0;
        }
        .oNO {
          flex: 0.33;
        }
        .orderNumber {
          button {
            font-size: 14px;

            color: #1e2997;
            line-height: 20px;
            background-color: white;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .datePresentation {
          text-align: center;
        }
        .upLoad {
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
    }
    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
