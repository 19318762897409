var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.fullscreenLoading),expression:"fullscreenLoading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"id":"userOrder"}},[_c('Dialog',{ref:"dialog",attrs:{"config":_vm.config}}),_c('div',[_c('div',{staticClass:"searchBox"},[_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":_vm.$fanyi('至'),"start-placeholder":_vm.$fanyi('开始日期'),"end-placeholder":_vm.$fanyi('结束日期'),"value-format":"yyyy-MM-dd HH:mm:ss","default-time":['00:00:00', '23:59:59']},on:{"change":_vm.setFromDate},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('button',{staticClass:"searchBtn",on:{"click":function($event){_vm.form.page = 1;
          _vm.getData(_vm.form);}}},[_vm._v(" "+_vm._s(_vm.$fanyi("搜索"))+" ")])],1),_c('div',{staticClass:"dataBody"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
          'text-align': 'center',
          background: '#F0F0F0',
          color: 'black',
          'font-size': '12px',
          'font-weight': '500',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"type":"index","width":"50","label":"Nº"}}),_c('el-table-column',{attrs:{"prop":"created_at","label":_vm.$fanyi('时间'),"width":"100"}}),_c('el-table-column',{attrs:{"label":_vm.$fanyi('订单号'),"width":"176"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"order_sn",on:{"click":function($event){return _vm.$fun.routerToPage(
                  '/OrderDetails?order_sn=' +
                    scope.row.order_sn +
                    '&type=purchased',
                  true
                )}}},[_vm._v(" "+_vm._s(scope.row.order_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"porder_sn","label":_vm.$fanyi('跟踪号码'),"width":"186"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"order_sn",on:{"click":function($event){return _vm.$fun.routerToPage(
                  '/deliveryDetails?type=purchase&order_sn=' +
                    scope.row.porder_sn
                )}}},[_vm._v(" "+_vm._s(scope.row.porder_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"bill_method_name","label":_vm.$fanyi('支付方式')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$fanyi(scope.row.bill_method_name))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"invoice_id","label":_vm.$fanyi('发票号'),"width":"144"}}),_c('el-table-column',{attrs:{"prop":"totalAmout","label":_vm.$fanyi('总金额')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(_vm.$fun.EURNumSegmentation(scope.row.totalAmout))+" €")])]}}])}),_c('el-table-column',{attrs:{"prop":"count","label":_vm.$fanyi('下载')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticClass:"downloadIcon",attrs:{"src":require("../../../../assets/user-img/download.svg"),"alt":""},on:{"click":function($event){return _vm.downLoad(scope.row)}}}),(scope.row.invoice_file)?_c('a',{attrs:{"href":scope.row.invoice_file}},[_c('img',{staticClass:"downloadIcon",attrs:{"src":require("../../../../assets/user-img/down_red.png"),"alt":""}})]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"allpag"},[_c('span'),_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-size":10,"page-sizes":[10, 20, 30, 50],"layout":" prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }